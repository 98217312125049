var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "slide-viewer",
      staticClass: "mb-2",
      attrs: { id: "slide-viewer-component" },
    },
    [
      _vm.slideLoaded
        ? _c(
            "div",
            { ref: "slide", staticClass: "slide-container" },
            [
              _vm.renderedCurrentSlide
                ? [
                    _c("company-name-and-progress-bar"),
                    _vm.showStageCompletedSlide
                      ? _c("stage-completed-slide", {
                          ref: "stage-completed-slide",
                          on: { continue: _vm.continueFromPseudoSlide },
                        })
                      : _vm.showEndOfStageSlide
                      ? _c("end-of-stage-slide", {
                          ref: "end-of-stage-slide",
                          on: {
                            continue: _vm.continueFromPseudoSlide,
                            back: _vm.resetPseudoSlideState,
                          },
                        })
                      : _vm.currentSlideLayoutType === "checkout"
                      ? _c(
                          "checkout-slide",
                          _vm._g(
                            {
                              ref: "checkout-slide",
                              attrs: { slide: _vm.renderedCurrentSlide },
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "collectData"
                      ? _c(
                          "collect-data-slide",
                          _vm._g(
                            {
                              key: _vm.currentSlide.id,
                              ref: "collect-data-slide",
                              attrs: {
                                slide: _vm.renderedCurrentSlide,
                                "selected-jurisdiction": _vm.jurisdiction,
                              },
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "companyDetails"
                      ? _c(
                          "company-details-slide",
                          _vm._g(
                            {
                              ref: "company-details-slide",
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "companySelect"
                      ? _c(
                          "company-select-slide",
                          _vm._g(
                            {
                              ref: "company-select-slide",
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "filingOptions"
                      ? _c(
                          "filing-options-slide",
                          _vm._g(
                            {
                              ref: "filing-options-slide",
                              attrs: {
                                "slide-products":
                                  _vm.renderedCurrentSlide.products,
                              },
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "productOptions"
                      ? _c(
                          "product-options-slide",
                          _vm._g(
                            {
                              ref: "product-select-slide",
                              attrs: {
                                "slide-products":
                                  _vm.renderedCurrentSlide.products,
                              },
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "options"
                      ? _c(
                          "options-slide",
                          _vm._g(
                            {
                              ref: "options-slide",
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "orderRequiringAttention"
                      ? _c(
                          "order-requiring-attention-slide",
                          _vm._g(
                            {
                              ref: "order-requiring-attention-slide",
                              attrs: { slide: _vm.renderedCurrentSlide },
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "document"
                      ? _c(
                          "document-slide",
                          _vm._g(
                            {
                              key: _vm.currentSlide.id,
                              ref: "document-slide",
                              staticClass: "document-slide",
                              attrs: {
                                slide: _vm.renderedCurrentSlide,
                                "read-only": _vm.readOnly,
                              },
                              on: {
                                collapseSidebar: function ($event) {
                                  return _vm.$emit("collapseSidebar")
                                },
                                "document-complete": _vm.documentComplete,
                                "previous-slide": _vm.previousSlide,
                                "next-slide": _vm.next,
                                "change-data": _vm.jumpToSlide,
                              },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "documentDownload"
                      ? _c(
                          "document-download-slide",
                          _vm._g(
                            {
                              key: _vm.currentSlide.id,
                              ref: "document-download-slide",
                              attrs: { slide: _vm.renderedCurrentSlide },
                              on: {
                                "next-slide": _vm.next,
                                "previous-slide": _vm.previousSlide,
                              },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "documentUpload"
                      ? _c(
                          "document-upload-slide",
                          _vm._g(
                            {
                              key: _vm.currentSlide.id,
                              ref: "document-upload-slide",
                              attrs: { slide: _vm.renderedCurrentSlide },
                              on: {
                                "next-slide": _vm.next,
                                "previous-slide": _vm.previousSlide,
                              },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "missingData"
                      ? _c(
                          "missing-data-slide",
                          _vm._g(
                            {
                              key: _vm.currentSlide.id,
                              ref: "missing-data-slide",
                              attrs: { slide: _vm.renderedCurrentSlide },
                              on: {
                                "next-slide": _vm.next,
                                "previous-slide": _vm.previousSlide,
                              },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "processFiling"
                      ? _c(
                          "pending-filing-slide",
                          _vm._g(
                            {
                              key: _vm.currentSlide.id,
                              ref: "pending-filing-slide",
                              attrs: { slide: _vm.renderedCurrentSlide },
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "product"
                      ? _c(
                          "product-slide",
                          _vm._g(
                            {
                              ref: "product-slide",
                              attrs: {
                                slide: _vm.renderedCurrentSlide,
                                "selected-jurisdiction": _vm.jurisdiction,
                              },
                              on: {
                                "utility-complete": _vm.handleUtilityComplete,
                              },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "serviceConfiguration"
                      ? _c("service-configuration-slide", {
                          ref: "service-configuration-slide",
                          attrs: { slide: _vm.renderedCurrentSlide },
                          on: { "next-slide": _vm.next },
                        })
                      : _vm.currentSlideLayoutType === "serviceInfo"
                      ? _c("service-info-slide", {
                          ref: "service-info-slide",
                          attrs: { slide: _vm.renderedCurrentSlide },
                          on: { "next-slide": _vm.next },
                        })
                      : _vm.currentSlideLayoutType === "verifyCheckout"
                      ? _c(
                          "verify-checkout-slide",
                          _vm._g(
                            {
                              ref: "verify-checkout-slide",
                              attrs: {
                                "disable-verify":
                                  _vm.currentStageHasUnsupportedDocumentSlide,
                                slide: _vm.renderedCurrentSlide,
                                "read-only": _vm.readOnly,
                              },
                              on: { "next-slide": _vm.next },
                            },
                            _vm.$listeners
                          )
                        )
                      : _vm.currentSlideLayoutType === "verifyOrder"
                      ? _c(
                          "verify-order-slide",
                          _vm._g(
                            {
                              ref: "verify-order-slide",
                              attrs: {
                                slide: _vm.renderedCurrentSlide,
                                "read-only": _vm.readOnly,
                              },
                              on: {
                                collapseSidebar: function ($event) {
                                  return _vm.$emit("collapseSidebar")
                                },
                                "next-slide": _vm.next,
                              },
                            },
                            _vm.$listeners
                          )
                        )
                      : _c(
                          "resting-slide",
                          _vm._g(
                            {
                              ref: "resting-slide",
                              attrs: { slide: _vm.renderedCurrentSlide },
                            },
                            _vm.$listeners
                          )
                        ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.showSlideControls,
                            expression: "showSlideControls",
                          },
                        ],
                        staticClass: "mt-3 mb-0 slide-viewer-button-container",
                      },
                      [
                        _vm.productUtilityButtonShow
                          ? _c(
                              "b-button",
                              {
                                staticClass: "btn utility-button btn-preferred",
                                attrs: {
                                  "aria-label":
                                    _vm.utilityButtonText + " button",
                                  disabled:
                                    _vm.utilityButtonDisabled ||
                                    _vm.utilityActionInProgress,
                                },
                                on: { click: _vm.utilityPressed },
                              },
                              [
                                _vm.utilityActionInProgress
                                  ? _c("b-spinner")
                                  : _c("span", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.utilityButtonText) +
                                          "\n          "
                                      ),
                                    ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "slide-viewer-button-row" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showBackButton,
                                    expression: "showBackButton",
                                  },
                                ],
                                staticClass: "back-button",
                                attrs: {
                                  disabled: _vm.backButtonDisabled,
                                  variant: "default",
                                  "aria-label": "back button",
                                },
                                on: { click: _vm.back },
                              },
                              [
                                _c("fa-icon", {
                                  staticClass: "fa-xs",
                                  attrs: { icon: "chevron-left" },
                                }),
                                _vm._v(
                                  " \n            " +
                                    _vm._s(
                                      _vm.currentSlide.config?.back_button
                                        ?.text || "Previous"
                                    ) +
                                    "\n          "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showContinueButton,
                                    expression: "showContinueButton",
                                  },
                                ],
                                staticClass: "next-button",
                                attrs: {
                                  disabled: _vm.continueButtonDisabled,
                                  variant: "primary",
                                  "aria-label": "next button",
                                },
                                on: { click: _vm.next },
                              },
                              [
                                _vm.slideLoaded
                                  ? _c(
                                      "span",
                                      { staticStyle: { padding: "0 0.5rem" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.continueButtonText) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _c("b-spinner", {
                                      attrs: { small: "", variant: "light" },
                                    }),
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.showFinishedButton,
                                    expression: "showFinishedButton",
                                  },
                                ],
                                staticClass: "finished-button",
                                attrs: {
                                  variant: "primary",
                                  "aria-label": "finish button",
                                },
                                on: { click: _vm.finish },
                              },
                              [
                                _vm.slideLoaded
                                  ? _c(
                                      "span",
                                      { staticStyle: { padding: "0 0.5rem" } },
                                      [
                                        _vm._v(
                                          "\n              Finish\n            "
                                        ),
                                      ]
                                    )
                                  : _c("b-spinner", {
                                      attrs: { small: "", variant: "light" },
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.errorMessage
                      ? _c("div", { staticClass: "errorMessage" }, [
                          _vm._v(
                            "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "client-feedback-sidebar-position" },
                      [_c("client-feedback-sidebar")],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { attrs: { id: "no-slides-available" } },
                      [
                        _c("h1", { staticStyle: { margin: "0 0 10px 0" } }, [
                          _vm._v(
                            "\n          There's nothing left to do on this Step.\n        "
                          ),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "40px" } }, [
                          _vm._v(
                            "\n          Looks like everything on this step has been taken care of.\n          Click to continue your company Stageline.\n        "
                          ),
                        ]),
                        _c("div", { staticClass: "image-container" }, [
                          _c("picture", [
                            _c("source", {
                              attrs: {
                                srcset: require("@images/illustrations/stageline/stageline-continue-sign.webp"),
                                type: "image/webp",
                              },
                            }),
                            _c("img", {
                              staticClass: "image",
                              attrs: {
                                src: require("@images/illustrations/stageline/stageline-continue-sign.png"),
                                alt: "Continue",
                              },
                            }),
                          ]),
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "next-button",
                            attrs: {
                              variant: "primary",
                              "aria-label": "continue button",
                            },
                            on: { click: _vm.next },
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { padding: "0 .5rem" } },
                              [_vm._v("\n            Continue\n          ")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          )
        : _c("ct-centered-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }