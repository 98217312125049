<template>
  <div
    id="slide-viewer-component"
    ref="slide-viewer"
    class="mb-2"
  >
    <div
      v-if="slideLoaded"
      ref="slide"
      class="slide-container"
    >
      <!-- Slide Components ----------------------------------------------------------------------->
      <template v-if="renderedCurrentSlide">
        <!-- Only renders if stagelineConfig?.show_company_name or stagelineConfig?.show_progress_bar -->
        <company-name-and-progress-bar />

        <!-- Pseudoslides ------------------------------------------------------------------------->
        <stage-completed-slide
          v-if="showStageCompletedSlide"
          ref="stage-completed-slide"
          @continue="continueFromPseudoSlide"
        />
        <end-of-stage-slide
          v-else-if="showEndOfStageSlide"
          ref="end-of-stage-slide"
          @continue="continueFromPseudoSlide"
          @back="resetPseudoSlideState"
        />

        <!-- Named Layout Slides ------------------------------------------------------------------>
        <checkout-slide
          v-else-if="currentSlideLayoutType === 'checkout'"
          ref="checkout-slide"
          :slide="renderedCurrentSlide"
          v-on="$listeners"
          @next-slide="next"
        />
        <collect-data-slide
          v-else-if="currentSlideLayoutType === 'collectData'"
          ref="collect-data-slide"
          :key="currentSlide.id"
          :slide="renderedCurrentSlide"
          :selected-jurisdiction="jurisdiction"
          v-on="$listeners"
          @next-slide="next"
        />
        <company-details-slide
          v-else-if="currentSlideLayoutType === 'companyDetails'"
          ref="company-details-slide"
          v-on="$listeners"
          @next-slide="next"
        />
        <company-select-slide
          v-else-if="currentSlideLayoutType === 'companySelect'"
          ref="company-select-slide"
          v-on="$listeners"
          @next-slide="next"
        />
        <filing-options-slide
          v-else-if="currentSlideLayoutType === 'filingOptions'"
          ref="filing-options-slide"
          :slide-products="renderedCurrentSlide.products"
          v-on="$listeners"
          @next-slide="next"
        />
        <product-options-slide
          v-else-if="currentSlideLayoutType === 'productOptions'"
          ref="product-select-slide"
          :slide-products="renderedCurrentSlide.products"
          v-on="$listeners"
          @next-slide="next"
        />
        <options-slide
          v-else-if="currentSlideLayoutType === 'options'"
          ref="options-slide"
          v-on="$listeners"
          @next-slide="next"
        />
        <order-requiring-attention-slide
          v-else-if="currentSlideLayoutType === 'orderRequiringAttention'"
          ref="order-requiring-attention-slide"
          :slide="renderedCurrentSlide"
          v-on="$listeners"
          @next-slide="next"
        />
        <document-slide
          v-else-if="currentSlideLayoutType === 'document'"
          ref="document-slide"
          :key="currentSlide.id"
          class="document-slide"
          :slide="renderedCurrentSlide"
          :read-only="readOnly"
          @collapseSidebar="$emit('collapseSidebar')"
          @document-complete="documentComplete"
          @previous-slide="previousSlide"
          v-on="$listeners"
          @next-slide="next"
          @change-data="jumpToSlide"
        />
        <document-download-slide
          v-else-if="currentSlideLayoutType === 'documentDownload'"
          ref="document-download-slide"
          :key="currentSlide.id"
          :slide="renderedCurrentSlide"
          v-on="$listeners"
          @next-slide="next"
          @previous-slide="previousSlide"
        />
        <document-upload-slide
          v-else-if="currentSlideLayoutType === 'documentUpload'"
          ref="document-upload-slide"
          :key="currentSlide.id"
          :slide="renderedCurrentSlide"
          v-on="$listeners"
          @next-slide="next"
          @previous-slide="previousSlide"
        />
        <missing-data-slide
          v-else-if="currentSlideLayoutType === 'missingData'"
          ref="missing-data-slide"
          :key="currentSlide.id"
          :slide="renderedCurrentSlide"
          v-on="$listeners"
          @next-slide="next"
          @previous-slide="previousSlide"
        />
        <pending-filing-slide
          v-else-if="currentSlideLayoutType === 'processFiling'"
          ref="pending-filing-slide"
          :key="currentSlide.id"
          :slide="renderedCurrentSlide"
          v-on="$listeners"
          @next-slide="next"
        />
        <product-slide
          v-else-if="currentSlideLayoutType === 'product'"
          ref="product-slide"
          :slide="renderedCurrentSlide"
          :selected-jurisdiction="jurisdiction"
          @utility-complete="handleUtilityComplete"
          v-on="$listeners"
        />
        <service-configuration-slide
          v-else-if="currentSlideLayoutType === 'serviceConfiguration'"
          ref="service-configuration-slide"
          :slide="renderedCurrentSlide"
          @next-slide="next"
        />
        <service-info-slide
          v-else-if="currentSlideLayoutType === 'serviceInfo'"
          ref="service-info-slide"
          :slide="renderedCurrentSlide"
          @next-slide="next"
        />
        <verify-checkout-slide
          v-else-if="currentSlideLayoutType === 'verifyCheckout'"
          ref="verify-checkout-slide"
          :disable-verify="currentStageHasUnsupportedDocumentSlide"
          :slide="renderedCurrentSlide"
          :read-only="readOnly"
          v-on="$listeners"
          @next-slide="next"
        />
        <verify-order-slide
          v-else-if="currentSlideLayoutType === 'verifyOrder'"
          ref="verify-order-slide"
          :slide="renderedCurrentSlide"
          :read-only="readOnly"
          @collapseSidebar="$emit('collapseSidebar')"
          v-on="$listeners"
          @next-slide="next"
        />
        <!-- Default Slide ------------------------------------------------------------------------>
        <resting-slide
          v-else
          ref="resting-slide"
          :slide="renderedCurrentSlide"
          v-on="$listeners"
        />

        <!-- Slide Controls ----------------------------------------------------------------------->
        <div v-show="showSlideControls" class="mt-3 mb-0 slide-viewer-button-container">
          <b-button
            v-if="productUtilityButtonShow"
            class="btn utility-button btn-preferred"
            :aria-label="utilityButtonText + ' button'"
            :disabled="utilityButtonDisabled || utilityActionInProgress"
            @click="utilityPressed"
          >
            <b-spinner v-if="utilityActionInProgress" />
            <span v-else>
              {{ utilityButtonText }}
            </span>
          </b-button>
          <div class="slide-viewer-button-row">
            <b-button
              v-show="showBackButton"
              class="back-button"
              :disabled="backButtonDisabled"
              variant="default"
              aria-label="back button"
              @click="back"
            >
              <fa-icon icon="chevron-left" class="fa-xs" />&nbsp;
              {{ currentSlide.config?.back_button?.text || 'Previous' }}
            </b-button>
            <b-button
              v-show="showContinueButton"
              class="next-button"
              :disabled="continueButtonDisabled"
              variant="primary"
              aria-label="next button"
              @click="next"
            >
              <span v-if="slideLoaded" style="padding: 0 0.5rem">
                {{ continueButtonText }}
              </span>
              <b-spinner v-else small variant="light" />
            </b-button>
            <b-button
              v-show="showFinishedButton"
              class="finished-button"
              variant="primary"
              aria-label="finish button"
              @click="finish"
            >
              <span v-if="slideLoaded" style="padding: 0 0.5rem">
                Finish
              </span>
              <b-spinner v-else small variant="light" />
            </b-button>
          </div>
        </div>
        <div v-if="errorMessage" class="errorMessage">
          {{ errorMessage }}
        </div>
        <!-- Client Feedback for Mobile------------------------------------------------------------>
        <div class="client-feedback-sidebar-position">
          <client-feedback-sidebar />
        </div>
      </template>

      <!-- No Slides Available -------------------------------------------------------------------->
      <template v-else>
        <div id="no-slides-available">
          <h1 style="margin: 0 0 10px 0">
            There's nothing left to do on this Step.
          </h1>
          <p style="margin-bottom: 40px;">
            Looks like everything on this step has been taken care of.
            Click to continue your company Stageline.
          </p>
          <div class="image-container">
            <picture>
              <source
                :srcset="require('@images/illustrations/stageline/stageline-continue-sign.webp')"
                type="image/webp"
              >
              <img
                :src="require('@images/illustrations/stageline/stageline-continue-sign.png')"
                alt="Continue"
                class="image"
              >
            </picture>
          </div>
          <b-button
            class="next-button"
            variant="primary"
            aria-label="continue button"
            @click="next"
          >
            <span style="padding: 0 .5rem">
              Continue
            </span>
          </b-button>
        </div>
      </template>
    </div>

    <ct-centered-spinner v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SERVICE_TYPES_MULTI_PURCHASABLE } from '@/common/modules/constants'
import { makeToastMixin } from '@/mixins/makeToastMixin'

// Responsibilities and Notes:
// 1. Manage specific Slide
// 2. Manage saving data or any interaction at next slide level

export default {
  name: 'SlideViewer',
  components: {
    CtCenteredSpinner:            () => import('@/components/shared/CtCenteredSpinner'),
    CompanyNameAndProgressBar:    () => import('@/components/StagelineV2/shared/CompanyNameAndProgressBar'),
    ClientFeedbackSidebar:        () => import('./clientFeedback/ClientFeedbackSidebar'),

    // psuedoslides --------------------------------------------------------------------------------
    StageCompletedSlide:          () => import('./slides/StageCompletedSlide'),
    EndOfStageSlide:              () => import('./slides/EndOfStageSlide'),

    // slides --------------------------------------------------------------------------------------
    CheckoutSlide:                () => import('./slides/checkout/CheckoutSlide'),
    CollectDataSlide:             () => import('./slides/collectData/CollectDataSlide'),
    CompanyDetailsSlide:          () => import('./slides/companyDetails/CompanyDetailsSlide'),
    CompanySelectSlide:           () => import('./slides/companySelect/CompanySelectSlide'),
    DocumentDownloadSlide:        () => import('./slides/documentDownload/DocumentDownloadSlide'),
    DocumentSlide:                () => import('./slides/document/DocumentSlide'),
    DocumentUploadSlide:          () => import('./slides/documentUpload/DocumentUploadSlide'),
    FilingOptionsSlide:           () => import('./slides/filingOptions/FilingOptionsSlide'),
    MissingDataSlide:             () => import('./slides/missingData/MissingDataSlide'),
    OptionsSlide:                 () => import('./slides/options/OptionsSlide'),
    OrderRequiringAttentionSlide: () => import('./slides/orderRequiringAttention/OrderRequiringAttentionSlide'),
    PendingFilingSlide:           () => import('./slides/pendingFiling/PendingFilingSlide'),
    ProductOptionsSlide:          () => import('./slides/product/ProductOptionsSlide'),
    ProductSlide:                 () => import('./slides/product/ProductSlide'),
    RestingSlide:                 () => import('./slides/resting/RestingSlide'),
    ServiceConfigurationSlide:    () => import('./slides/serviceConfiguration/ServiceConfigurationSlide'),
    ServiceInfoSlide:             () => import('./slides/serviceInfo/ServiceInfoSlide'),
    VerifyCheckoutSlide:          () => import('./slides/verifyCheckout/VerifyCheckoutSlide'),
    VerifyOrderSlide:             () => import('./slides/verifyOrder/VerifyOrderSlide'),
  },
  mixins: [makeToastMixin],
  data() {
    return {
      errorMessage: null,
      utilityButtonWasClicked: false,
      utilityComplete: false,
      filterProducts: [],
    }
  },
  computed: {
    ...mapGetters('checkout', [
      'processingCheckout',
      'cartEmpty',
    ]),
    ...mapGetters('stageline', [
      'captureMonsterSelectedProductTypeNotSet',
      'cartItemExistsByCategory',
      'company',
      'currentPeriod',
      'currentPeriodName',
      'currentPeriodSlides',
      'currentSlide',
      'currentSlideIndex',
      'currentSlideIsFirstOfCurrentStage',
      'currentSlideIsLastOfCurrentPeriod',
      'currentSlideIsLastOfCurrentStage',
      'currentSlideLayoutType',
      'currentSlidePeriodIndex',
      'currentSlideProductSelectionRequired',
      'currentStage',
      'currentStageHasUnsupportedDocumentSlide',
      'currentStep',
      'currentStepIndex',
      'jurisdiction',
      'slideIsLast',
      'slideLoaded',
      'slidePeriodIndex',
      'showEndOfStageSlide',
      'isDomainOnlyCompany',
    ]),
    ...mapGetters('companies', [
      'hasProductOfTypeWithCompanyOrInCart',
      'hasProductBundleWithCompanyOrInCartByProductCategory',
      'formedElsewhere',
      'registrations',
    ]),
    readOnly() {
      return this.currentStage.name === 'form_the_company' &&
        this.currentStage.completed_at &&
        !this.showStageCompletedSlide
    },
    showStageCompletedSlide() {
      return this.formedElsewhere && this.currentStage.name === 'form_the_company'
    },
    continueButtonText() {
      if (this.utilityButtonWasClicked || this.resetContinueButtonText) {
        return 'Continue'
      } else {
        return this.currentSlide?.config?.continue_button?.text || 'Continue'
      }
    },
    renderedCurrentSlide() {
      this.populateDynamicFields()
      return this.currentSlide
    },
    productUtilityButtonShow() {
      return this.currentSlide?.layout_type === 'product' &&
        this.currentSlide?.products.length === 1
    },
    utilityButtonText() {
      if (this.productUtilityButtonShow) {
        const firstProduct = this.currentSlide.products[0]
        if (firstProduct?.button_text) return firstProduct.button_text

        switch (firstProduct.product_kind) {
          case 'registered_agent_product':
          case 'subscription_bundle':
            return 'Add this service to your cart'
          case 'filing_product':
            return 'Add this filing to your cart'
          default:
            return 'Add this product to your cart'
        }
      }
      else return ''
    },
    utilityButtonDisabled() {
      const self = this
      return self.currentSlide.products.some(product => {
        if (Object.values(SERVICE_TYPES_MULTI_PURCHASABLE).includes(product.product_category)) {
          return false
        } else if (product.product_kind === 'product_bundle') {
          return self.hasProductBundleWithCompanyOrInCartByProductCategory(product.product_category)
        } else {
          return self.hasProductOfTypeWithCompanyOrInCart(product.product_category, self.jurisdiction)
        }
      })
    },
    resetContinueButtonText() {
      const self = this
      return this.currentSlide.products.some(function(product) {
        switch (product.product_kind) {
          case 'registered_agent_product':
            product.product_category = 'registered-agent'
            break
          case 'subscription_bundle':
            product.product_category = 'subscription-bundle'
        }
        return self.cartItemExistsByCategory(product.product_category)
      })
    },
    currentSlideRef() {
      return this.currentSlide.layout_type + '-slide'
    },
    showSlideControls() {
      return (this.productUtilityButtonShow || this.showBackButton || this.showContinueButton)
        && !this.showStageCompletedSlide && !this.showEndOfStageSlide
    },
    jurisdictionName() {
      return this.jurisdiction ? this.jurisdiction.state_province_region : ''
    },
    companyName() {
      return this.company?.name
    },
    entityTypeName() {
      return this.company?.entity_type
    },
    ein() {
      const federalRegistration = this.registrations?.find(item =>
        item?.jurisdiction?.state_province_region === 'Federal' &&
        item?.company_id === this.company?.id
      )

      return federalRegistration?.entity_id || ''
    },
    showBackButton() {
      return this.currentSlideLayoutType !== 'processFiling' && (
        (
          (this.currentStepIndex > 0 || this.hasPeriodIndexAndNotFirstSlideOfStage) &&
          !this.currentSlide.config?.back_button?.hide
        )
      )
    },
    hasPeriodIndexAndNotFirstSlideOfStage() {
      return this.currentSlidePeriodIndex > 0 && !this.currentSlideIsFirstOfCurrentStage
    },
    showContinueButton() {
      return (this.currentSlidePeriodIndex < this.currentPeriodSlides.length - 1 &&
        !this.currentSlide.config?.continue_button?.hide) ||
        (this.cartEmpty && ['checkout', 'verifyCheckout'].includes(this.currentSlideLayoutType))
    },
    showFinishedButton() {
      if (!this.cartEmpty && ['checkout', 'verifyCheckout'].includes(this.currentSlideLayoutType)) return false
      return this.currentSlidePeriodIndex === this.currentPeriodSlides.length - 1
    },
    backButtonDisabled() {
      return ['checkout', 'verifyCheckout'].includes(this.currentSlideLayoutType)
        && this.processingCheckout
    },
    continueButtonDisabled() {
      return !this.slideLoaded || this.currentSlideProductSelectionRequired
    },
    utilityActionInProgress() {
      return this.utilityButtonWasClicked && !this.utilityComplete
    },
  },
  watch: {
    async currentSlide(newSlide, oldSlide) {
      this.resetPseudoSlideState()
      this.checkToSetSelectedStageProductType(newSlide)

      if (this.slidePeriodIndex(newSlide) > this.slidePeriodIndex(oldSlide)) {
        await this.transitionEnter('rtl')
      } else {
        await this.transitionEnter('ltr')
      }
    },
  },
  methods: {
    ...mapActions('stageline', [
      'completePeriod',
      'completeStage',
      'completeStageline',
      'updateCompanyStagelineProgress',
      'goToNextSlide',
      'goToPreviousSlide',
      'goToSlide',
      'goToFirstIncompleteSlideOfPreviousStage',
      'goToFirstIncompleteSlideOfNextIncompleteStage',
      'setSlideLoaded',
      'setShowEndOfStageSlide',
      'setCaptureMonsterSelectedProductType',
    ]),
    checkToSetSelectedStageProductType(newSlide) {
      if (this.captureMonsterSelectedProductTypeNotSet(newSlide, this.currentStage)) {
        this.setCaptureMonsterSelectedProductType(newSlide)
      }
    },
    async transitionEnter(direction) {
      const _this = this
      let distance
      switch (direction) {
        case 'rtl': distance = '25px'; break
        case 'ltr': distance = '-25px'; break
      }
      _this.$nextTick(async () => {
        const slide = this.$refs['slide']
        if (slide) {
          slide.style.opacity = 0
          slide.style.left = distance
          slide.classList.add('slide-transition')
          slide.style.opacity = 1
          slide.style.left = '0px'
          await new Promise(resolve => setTimeout(resolve, 300)) // wait for 300ms
          slide.classList.remove('slide-transition')
        }
      })
    },
    async transitionLeave(direction) {
      let distance
      switch (direction) {
        case 'rtl': distance = '-25px'; break
        case 'ltr': distance = '25px'; break
      }
      const slide = this.$refs['slide']
      if (slide) {
        slide.style.left = '0px'
        slide.classList.add('slide-transition')
        slide.style.opacity = 0
        slide.style.left = distance
        await new Promise(resolve => setTimeout(resolve, 300)) // wait for 300ms
        slide.classList.remove('slide-transition')
      }
    },
    reset() {
      this.errorMessage = null
      this.utilityButtonWasClicked = false
      this.utilityComplete = false
    },
    showContactModal() {
      this.$emit('show-contact-modal')
    },
    async back() {
      await this.transitionLeave('ltr')
      this.setSlideLoaded(false)
      // TODO this.currentPeriodName === 'start' below is a hack, but since CTA only has one stage
      //  and step, it'll get us over the finish line, fix later
      if (this.currentPeriodName === 'start' && this.currentStepIndex === 0 && this.currentSlideIndex === 0) {
        this.goToFirstIncompleteSlideOfPreviousStage()
      } else {
        this.goToPreviousSlide()
      }
      await this.transitionEnter('ltr')
    },
    async next() {
      await this.transitionLeave('rtl')
      this.setSlideLoaded(false)

      try {
        if (!await this.saveSlideData()) {
          this.setSlideLoaded(true)
          return
        }

        // commenting out for now since it breaks after completing the CTA stage
        if (this.currentSlideIsLastOfCurrentPeriod) {
          await this.finish()
        }
        else if (this.currentSlideIsLastOfCurrentStage) {
          if (this.isDomainOnlyCompany) {
            await this.completeStage(this.currentStage.id)
            this.continueFromPseudoSlide()
          } else {
            this.setSlideLoaded(true)
            this.setShowEndOfStageSlide(true)
          }

          await this.transitionEnter('rtl')
        } else {
          this.goToNextSlide()
        }
      } catch(error) {
        this.errorToast('Error', 'Please address invalid fields before progressing.')
      }
    },
    async finish() {
      this.loaded = false
      await this.completeStageline()
    },
    utilityPressed() {
      if (this.productUtilityButtonShow && this.$refs[this.currentSlideRef].utilityClicked) {
        this.$refs[this.currentSlideRef].utilityClicked()
      }
      this.utilityButtonWasClicked = true
    },
    async documentComplete() {
      await this.next()
    },
    async previousSlide() {
      this.back()
    },
    handleUtilityComplete() {
      this.utilityComplete = true
    },
    async saveSlideData() {
      let result = { valid: true, details: {} }
      this.errorMessage = null

      if (result.valid) {
        try {
          // Devs: disable await on this call during development to speed things up
          await this.updateCompanyStagelineProgress({ details: result['details'] })

          return true
        } catch (error) {
          this.errorToast('Error', 'An error has occurred when attempting to update your Stageline progress, please try again.')

          return false
        }
      } else {
        this.errorMessage = 'Please complete required items'
      }
      return result.valid
    },
    continueFromPseudoSlide() {
      this.setSlideLoaded(false)
      this.resetPseudoSlideState()
      this.goToFirstIncompleteSlideOfNextIncompleteStage()
    },
    populateDynamicFields() {
      if (this.currentSlide?.heading) {
        let currentHeading = this.currentPeriodSlides.find(s => s.id === this.currentSlide.id)?.heading
        this.currentSlide.heading = this.replaceDynamicFields(currentHeading)
      }

      if (this.currentSlide?.description) {
        let currentDesc = this.currentSlide.description
        this.currentSlide.description = this.replaceDynamicFields(currentDesc)
      }
    },
    replaceDynamicFields(content) {
      return content
        .replace('{{jurisdiction}}', this.jurisdictionName)
        .replace('{{companyName}}', this.companyName)
        .replace('{{entityType}}', this.entityTypeName)
        .replace('{{ein}}', this.ein)
    },
    resetPseudoSlideState() {
      this.setShowEndOfStageSlide(false)
    },
    jumpToSlide(slideName) {
      this.goToSlide(slideName)
    },
  },
}
</script>

<style lang="scss" scoped>
$green1: #00C795;

#slide-viewer-component {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  & > div:nth-of-type(2) {
    min-height: 15em;
  }

  .slide-container {
    position: relative;

    > div:nth-of-type(2) {
      min-height: 15em;
    }

    .client-feedback-sidebar-position {
      display: none;
    }
  }

  .document-slide {
    display: flex;
  }

  .slide-viewer-button-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 3.125em;
  }

  .slide-viewer-button-row {
    position: absolute;
    display: inherit;
    right: 0;

    .back-button {
      color: $ct-ui-primary;

      &:hover {
        background: $ct-ui-primary-light;
      }
    }
  }

  .question-circle {
    color: $blue;
  }

  .resource-name {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  ::v-deep .slide-heading {
    margin-top: 1.25em;
    padding-bottom: 0.625em;
    text-align: left;
  }

  ::v-deep .form-control,
  ::v-deep select {
    height: 3.75em;
  }
  ::v-deep [role=group] .form-control {
    margin-bottom: 0.625em;
  }

  .next-button,
  .finished-button {
    width: auto;
    min-width: 8.125em;
    padding: .6rem 0;
    background: $ct-ui-primary;
    border-color: $ct-ui-primary;

    &:hover {
      background: $ct-ui-primary-dark;
      border-color: $ct-ui-primary;
    }
  }
  .utility-button {
    font-weight: bolder;
    min-width: 8.125em;
    background: $green1 !important;
  }
  .errorMessage {
    text-align: right;
    color: red;
  }

  #no-slides-available {
    h1 {
      word-wrap: break-word;
      max-width: 90%;
    }
    .image-container {
      display: flex;
      justify-content: center;
      flex: 0 1 50%;

      picture {
        .image {
          height: 16.25em;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.slide-transition {
  transition: opacity 0.3s, left 0.3s;
}

@media only screen and (max-width: 1024px) {
  #slide-viewer-component {

    .slide-viewer-button-container {
      width: 100% !important;
      flex-flow: row nowrap;
      row-gap: 2.0em;
      padding-top: 2.0em;
      margin: 0;

      .slide-viewer-button-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        column-gap: 2.0em;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  #slide-viewer-component {

    .slide-container {

      .client-feedback-sidebar-position {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  #slide-viewer-component {
    padding-bottom: 2.0em;

    .slide-viewer-button-container {
      width: 100% !important;
      height: 9.375em !important;
      margin: 0;
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;

      .slide-viewer-button-row {
        position: relative;
        margin: 0 !important;
        padding-bottom: 1.0em;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        column-gap: 2.0em;
      }
    }
  }
}
</style>
